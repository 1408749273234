<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="listQuery.name" placeholder="请输学生姓名" style="width: 200px;" class="filter-item" clearable/>
      <el-button class="filter-item" type="primary" @click="handleFilter">搜索</el-button>
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      >
      <el-table-column label="序号" max-width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="学号"  align="center">
        <template slot-scope="scope">
          {{
           scope.row.student? scope.row.student.account:''
          }}
        </template>
      </el-table-column>
      <el-table-column label="学生姓名"  align="center">
        <template slot-scope="scope">
          {{
            scope.row.student?scope.row.student.name:''
          }}
        </template>
      </el-table-column>
      <el-table-column label="院系"  align="center">
        <template slot-scope="scope">
          {{
            scope.row.classes?scope.row.classes.college.name:''
          }}
        </template>
      </el-table-column>
      <el-table-column label="专业"  align="center">
        <template slot-scope="scope">
           {{
            scope.row.classes?scope.row.classes.major.name:''
          }}
        </template>
      </el-table-column>
      <el-table-column label="年级"  align="center">
        <template slot-scope="scope">
           {{
            scope.row.classes?scope.row.classes.grade.name:""
          }}
        </template>
      </el-table-column>
      <el-table-column label="班级"  align="center">
        <template slot-scope="scope">
          {{
            scope.row.classes?scope.row.classes.name:""
          }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="学生" min-width="110" align="center">
        <template slot-scope="scope">
        </template>
      </el-table-column> -->
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange"/>
    </div>
  </div>
</template>

<script>
  import request from '@/utils/request'
  export default {
    data() {
      return {
        listQuery: {
          page: 1,
          limit: 10,
          name: null,
          class_id:null
        },
        list: [],
        total: null,
        listLoading: false,
        form: {
          id: '',
        }
      }
    },
    created() {
      this.listQuery.class_id=this.$route.query.class_id
      this.getList()
    },
    methods: {
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      handleCheck() {
        this.$router.push(`/second/signup`);
      },
      getList() {
        this.listLoading = true
        request({
          url: '/api/schoolend/school/classStudent',
          method: 'get',
          params: this.listQuery
        }).then(response => {
          this.list = response.data.data
          this.total = response.data.total
          this.listLoading = false
        })
      },
      handleSizeChange(val) {
        this.listQuery.limit = val
        this.getList()
      },
      handleCurrentChange(val) {
      this.listQuery.page = val
      this.getList()
      },
    }
  }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.el-table--border th.gutter:last-of-type {
  display: block!important;
}
</style>
